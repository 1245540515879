@font-face {
  font-family: "Cabin";
  src: url('/public/Cabin.ttf');
  src: url('/public/Cabin.otf');
  font-weight: unset;
  -webkit-text-stroke: thin;
}

.App {
  text-align: left;
  font-family: Cabin;
  align-items: center;
  justify-content: center;
  font-size: calc(1vw);
 
}

 .column {
  float:left;
  margin: 1%;
  padding: 1%;
  background-color:#F3F5F8;
  font-family: Cabin;
  font-weight: bold;
  font-size: calc(1vw);
  color: black;
  text-align:justify;
  align-items: center;
  }

 .left {
  width: 66%;
  
 }

 .right {
  width: 26%;
  
   
 }

.Apptitulek {
  background-color:#0094DE;
  align-items: center;
  justify-content: center;
  font-family: Cabin;
  font-weight: bold;
  color: white;
  font-size: calc(1.7vw);
  margin-top: 10px;
  padding: 5px 20px;
  text-align: center;
  }

.App-header {
  background: linear-gradient(to bottom,black,white), url('./components/Navbar/slide5.jpg');
  background-image:url('./components/Navbar/slide5.jpg');
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  background-color: #000000;
  align-items: left;
  justify-content: left;
  font-family: Cabin;
  font-size: calc(3.5vw);
  font-weight: bold;
  color: white;
  padding: calc(0.8vw) calc(0.8vw) calc(0.8vw) calc(0.8vw);
  line-height: 1px;
 
}

.App-link {
  font-family: Cabin;
  font-weight: bold;
  color: #0094DE;
  }

.fotky{
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: calc(15vw);

}
.fotka_face{
 max-width: calc(15vw);
}

.mobil{
  font-family: Cabin;
  font-weight: bold;
  font-size: calc(1.5vw);
  color: white;
  padding-right: calc(1vw);
  }

  @media screen and (max-width: 600px) {
    .column.left, .column.right {
      width: 96%;
    }
  }

  .footer {
    margin-top: 20px;
    font-size: calc(1vw);
    background-color: white;
    text-align: center;
    padding: 10px;
  }

  .logo {
    box-align: left;
    display: block;
    max-width: calc(8vw);
    float:left;
    padding: calc(0.5vw) calc(0.1vw) calc(0.1vw) calc(0.0001vw);
  }

  #stripes {
    opacity: 0.9;
    margin-top: 1.2vw;
    height: 0.9%;
    min-width: 1vw;
    width: fit-content;
    background: repeating-linear-gradient(
      to right,
      red,
      red 5vw,
      transparent 5vw,
      transparent 10vw
    );}

    #road-sign {
      display: block;
      width: 1.5vw;
      height: 1.5vw;
      background-color: #FFFFFF;
      /*box-shadow: 10px 10px 5px #888888;*/
      border: 0.4vw solid #EE0000;
      border-radius: 2vw;
    }

    #speed-limit {
      text-align: center;
      padding-top: 20px;
      margin: auto; /*Will center the text*/
      color: #000000;
      font-family: Trebuchet MS;
      font-size: 90px;
      font-weight: bold;
    }

   